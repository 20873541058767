input,
select {
    background: var(--input-background-color);
    font-size: 16px;
    color: #000;
    letter-spacing: 0.27px;
    padding: 1.9rem 2.4rem 1.9rem 2.4rem;
    border: none;
    border-bottom: 1px solid var(--input-bottom-border-color);
    outline: none;
    border-radius: 0;
    width: 100%;

    &::placeholder {
        font-size: 16px;
        color: #000;
        letter-spacing: 0.27px;
    }

    &:user-invalid {
        color: red;
        border-bottom: 1px solid red;
    }
}

.form-invalid {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--white-color);
    padding: 10px 15px;
    text-align: center;
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: var(--black-color);
    box-shadow: 0 2px 4px grey;
    border-radius: 4px;
    width: 100%;
    z-index: 9998;
}

.is-date {
    position: relative;

    &::before {
        content: '';
        color: transparent;
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        right: 15px;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h22v22H0z'/%3E%3Cpath d='M16.507 8.267h1.373V9.64h-1.373V8.267Zm1.373 5.493h-1.373v1.373h1.373V13.76Zm-1.373-2.747h1.373v1.374h-1.373v-1.374ZM13.76 8.267h-1.373V9.64h1.373V8.267Zm-1.373 8.24h1.373v1.373h-1.373v-1.373Zm1.373-2.747h-1.373v1.373h1.373V13.76Zm-1.373-2.747h1.373v1.374h-1.373v-1.374ZM9.64 8.267H8.267V9.64H9.64V8.267Zm-1.373 8.24H9.64v1.373H8.267v-1.373ZM9.64 13.76H8.267v1.373H9.64V13.76Zm-1.373-2.747H9.64v1.374H8.267v-1.374ZM5.52 16.507H4.147v1.373H5.52v-1.373ZM4.147 13.76H5.52v1.373H4.147V13.76Zm1.373-2.747H4.147v1.374H5.52v-1.374ZM19.253 5.52H2.773V4.147h16.48V5.52Zm0 13.733H2.773V6.893h16.48v12.36Zm.687-16.48h-2.06V1.4h-1.373v1.373H5.52V1.4H4.147v1.373h-2.06L1.4 3.46v16.48l.687.687H19.94l.687-.687V3.46l-.687-.687Z' fill='%239E1B18'/%3E%3C/g%3E%3C/svg%3E");
    }

    & input {
        padding-right: 1.5rem;

        &::-webkit-calendar-picker-indicator {
            color: transparent;
            background: none;
            width: 28px;
            height: 28px;
            z-index: 1;
        }
    }
}

.form-row {
    margin-bottom: 35px;
    display: flex;
}

.alert {
    position: absolute;
    display: flex;
    min-width: 420px;
    max-width: 560px;
    background-color: var(--white-color);
    font-size: 16px;
    color: #000;
    line-height: 26px;
    font-weight: 500;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.27);
}

.alert-text {
    padding: 25px 25px 25px 39px;
}

.alert-icon {
    position: relative;
    width: 85px;
    flex-shrink: 0;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.alert-success {
    top: 0;

    & .alert-icon {
        background-color: var(--success-color);

        &::before {
            width: 32px;
            height: 32px;
            background-image: url("/img/icons/icon-pass.svg");
        }
    }
}

.alert-error {
    bottom: 0;

    @media screen and (min-width: 1200px) {
        bottom: 35px;
    }

    & .alert-icon {
        background-color: var(--second-color);

        &::before {
            width: 28px;
            height: 28px;
            background-image: url("/img/icons/icon-warning.svg");
        }
    }
}

.form-head {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--white-color);
    background-color: var(--second-color);

    & .container {
        align-items: flex-start;
    }
}

.form-required-legend {
    margin: 40px 0 0;
    width: 100%;
    padding: 24px 64px;
    background-color: var(--white-color);
    border-bottom: 1px solid var(--border-color);
}

.form-head__error-block {
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 25px;
    margin: 8px 8px 70px 8px;
    color: var(--black-color);
    border-radius: 2px;
    background-color: var(--white-color);

    &:after {
        content: '';
        position: absolute;
        left: -8px;
        right: -8px;
        top: -8px;
        bottom: -8px;
        border-radius: 2px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23fff' stroke-width='4' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }

    & + .form-head__error-block {
        margin-top: -20px;
    }

    & span {
        padding: 0 15px;
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

.form-head__error-icon {
    width: 28%;
    flex-shrink: 0;
    position: relative;
    padding-left: 37px;

    &:before {
        content: '';
        left: 0;
        position: absolute;
        width: 25px;
        height: 23px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.156 15.75v-7h2.188v7h-2.188Zm2.188 3.5V17.5h-2.188v1.75h2.188ZM12.25 2.24 2.24 21h19.985L12.25 2.24ZM11.48 0h1.54l11.445 21.455-.77 1.295H.77L0 21.455 11.48 0Z' fill='%239E1B18' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
}

.form-head__error-link {
    width: 142px;
    padding: 13px 0;
    flex-shrink: 0;
    margin-left: auto;
    text-align: center;
    border-radius: 2px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.23px;
    border: 2px solid var(--main-color);
    color: var(--main-color);
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
}

.form-headline__wrap {
    width: 100%;
    display: flex;
    align-items: baseline;

    & .form-headline {
        flex-shrink: 0;
        width: auto;
    }

    & .form-headline__sm {
        margin-left: auto;
    }
}

.form-headline {
    width: 95%;
    margin: 0;
    padding: 50px 0;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
}

.form-headline__sm {
    font-size: 1.8rem;
    line-height: 2.1rem;
}

.form-group-wrapper {
    width: 100%;
    padding: 24px 64px 50px 64px;
    background-color: white;
    border-radius: 2px;
    margin: 40px 0 0 0;

    &.is-empty {
        & .form-group-legend {
            border-color: transparent;
            margin-bottom: 0;
        }

        & .form-group-empty-text {
            display: flex;
        }
    }
}

[aria-labelledby="location-of-operation-unit"] {
    margin-top: 0;
}

.form-group-legend {
    color: var(--black-color);
    padding: 0 64px 24px 64px;
    margin: 0 -64px 50px -64px;
    border-bottom: solid #d8d8d8 1px;
    font-size: 2rem;
    line-height: 3.1rem;
    font-weight: 700;
}

.form-group-empty-text {
    display: none;
    padding: 75px 0;
    margin: 0 -64px -50px -64px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px dot-dash #9F9F9F;
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.6rem;
    color: #6f6f6f;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%239f9f9f' stroke-width='2' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.form-element {
    display: flex;
    flex-direction: column;
    padding-bottom: 36px;

    &.is-with-tooltip {
        flex-flow: row wrap;

        & .form-input-wrap,
        & .form-select {
            flex: 1 100%;
        }
    }

    & label {
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0.27px;
    }

    & .is-error {
        color: var(--error-color);
    }
}

.form-input-wrap,
.form-select {
    position: relative;
}

.form-select {
    display: flex;
    position: relative;

    &:after {
        content: "";
        width: 19px;
        height: 12px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        z-index: 3;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 2 9.5 9 2 2' stroke='%239E1B18' stroke-width='4' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
        pointer-events: none;
    }

    & select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-right: 4rem;
        text-overflow: ellipsis;

        &:hover {
            cursor: pointer;
        }
    }
}

.form-error {
    position: relative;
    color: var(--error-color);
    display: flex;
    margin-top: 15px;
    line-height: 2.1rem;

    &:before {
        content: '';
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h40v40H0z'/%3E%3Cpath d='M18.438 25V15h3.125v10h-3.125Zm3.125 5v-2.5h-3.125V30h3.125ZM20 5.7 5.7 32.5h28.55L20 5.7Zm-1.1-3.2h2.2l16.35 30.65-1.1 1.85H3.6l-1.1-1.85L18.9 2.5Z' fill='%23A60700'/%3E%3C/g%3E%3C/svg%3E");
    }
}

.form-grid-row {
    display: grid;
    grid-gap: 30px;
    align-items: flex-start;

    &.gta-1-1 {
        grid-template-areas: "a b";
        grid-auto-columns: 1fr 1fr;
    }

    &.gta-1-3 {
        grid-template-areas: "a b";
        grid-auto-columns: 1fr 3fr;
    }

    &.gta-3-1 {
        grid-template-areas: "a b";
        grid-auto-columns: 3fr 1fr;
    }
}

.radio-list-label {
    margin: 0 0 30px 0;
    font-weight: 700;

    & + .form-tooltip {
        margin-left: 10px;
        margin-top: -6px;
    }
}

.radio-list {
    display: flex;
    flex: 1 100%;
    padding: 0;
    margin: 0;
}

.radio-wrapper {
    min-height: 32px;
    margin-right: 20px;
    display: flex;
    align-items: center;

    & label {
        position: relative;
        padding: 6px 20px 6px 40px;
        font-weight: 400;

        &:before {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border: #9e1b18 2px solid;
            border-radius: 50%;
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            content: "";
            left: 16px;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: background-color 250ms 17ms ease;
        }
    }

    & input {
        &:focus-visible {
            &:checked + label {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }
        }

        &:focus {
            &:checked + label {
                @media not all and (min-resolution:.001dpcm) {
                    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                        outline: 1px solid var(--main-color);
                        outline-offset: 2px;
                    }
                }
            }
        }

        &:checked + label {
            &:after {
                background-color: #9e1b18;
            }
        }
    }
}

.hide {
    display: none;
}


.form-login {
    width: 100%;
    max-width: 304px;
    /*margin-top: 36px;
    margin-bottom: 80px;*/
    margin: 0;
    z-index: 1;

    & input {
        width: 100%;
    }

    & button {
        width: 100%;
        border: 4px solid var(--white-color);
    }
}

.form-login__shape {
    position: relative;
    margin: 114px 0 70px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 520px;
    height: 520px;
    flex-shrink: 0;

    &::before {
        position: absolute;
        left: 8rem;
        content: '';
        width: 100%;
        height: 100%;
        background: url("/img/icons/login-shape.svg") no-repeat;
        background-size: contain;
    }

    @media screen and (min-width: 1200px) {
        width: 600px;
        height: 600px;
    }

    @media screen and (min-width: 1400px) {
        width: 628px;
        height: 628px;
    }
}

/* ---------------------------------------------------
Form status(form success and errors pages)
--------------------------------------------------- */
.form-status {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 30px 25px;
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
    color: var(--white-color);

    &.is-with-steps {
        padding: 0;

        &.is-success {
            & .form-status__step1 {
                width: 43%;
                margin-right: 0;
                flex-shrink: 0;

                &:before,
                &:after {
                    border: 1px solid;
                    height: 50%;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: -30px;
                    transform: skewX(-28deg);
                }

                &:after {
                    transform: skewX(28deg);
                }
            }

            & .form-status__step2 {
                width: 50%;
                margin-left: auto;
                display: flex;
                color: var(--white-color);
            }
        }
    }

    &.is-success {
        background-color: var(--success-color);

        & .form-status__icon {
            width: 40px;
            height: 40px;
            background-image: url("/img/icons/icon-pass.svg");
            background-size: contain;
        }
    }

    &.is-error {
        background-color: var(--second-color);

        & .form-status__step1 {
            background-color: var(--second-color);
        }

        & .form-status__icon {
            width: 34px;
            height: 34px;
            background-image: url("/img/icons/icon-warning.svg");
        }

        & .form-status__text {
            width: auto;
        }
    }

    &.is-grey {
        background-color: var(--grey-bg-color);
    }
}

.form-status__step1,
.form-status__step2 {
    padding: 30px 25px;
}

.form-status__step2 {
    width: 33%;
    color: var(--black-color);
}

.form-status__step1 {
    width: 43%;
    margin-right: 87px;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--white-color);

    &:after {
        content: '';
        position: absolute;
        right: -30px;
        top: 0;
        width: 0;
        height: 0;
        border-width: 56px 0 56px 30px;
        border-color: transparent transparent transparent var(--second-color);
        border-style: solid;
    }
}

.form-status__icon {
    margin-right: 20px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
}

.form-status__text {
    width: 47%;
}

.form-status__list {
    margin-top: 40px;
    padding: 30px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%239f9f9f' stroke-width='4' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.form-status__list-item {
    display: flex;

    &:not(:first-child) {
        padding-top: 20px;
        margin-top: 30px;
        border-top: 1px solid var(--border-color);
    }
}

.form-status__list-item-text {
    position: relative;
    padding-left: 50px;
    margin-right: 45px;
    width: 160px;
    flex-shrink: 0;
    font-size: 2rem;
    line-height: 3.1rem;
    font-weight: 700;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 25px;
        height: 23px;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.156 15.75v-7h2.188v7h-2.188Zm2.188 3.5V17.5h-2.188v1.75h2.188ZM12.25 2.24 2.24 21h19.985L12.25 2.24ZM11.48 0h1.54l11.445 21.455-.77 1.295H.77L0 21.455 11.48 0Z' fill='%239E1B18' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
}

.form-status__list-sub {
    padding: 0;
}

.form-status__list-sub-item {
    display: flex;

    &:not(:first-child) {
        margin-top: 17px;
    }

    & strong {
        width: 180px;
        flex-shrink: 0;
        margin-right: 55px;
    }
}

.form-tooltip {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: auto;
    position: relative;
    display: inline-block;

    &:before {
        content: '';
        width: 40px;
        height: 45px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    &:hover,
    &:focus {
        cursor: help;
        pointer-events: auto;

        & .form-tooltip__content {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0px) translateX(-50%);
        }

        & .tooltip-icon {
            & circle {
                fill: var(--main-color);
            }

            & text {
                fill: var(--white-color);
            }
        }
    }

    &:focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

    &:focus {
        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }
        }
    }
}

.tooltip-icon {
    position: absolute;
}

.form-tooltip__content {
    position: absolute;
    bottom: 100%;
    left: 50%;
    min-width: 300px;
    max-width: 350px;
    padding: 30px 26px;
    background-color: white;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.27px;
    border-radius: 2px;
    box-shadow: 0 12px 44px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 22px;
    z-index: 99;
    pointer-events: none;
    transform: translateY(10px) translateX(-50%);
    opacity: 0;
    transition: all 0.25s ease-out;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 11.5px 0 11.5px;
        border-color: var(--white-color) transparent transparent transparent;
    }

    & a {
        color: var(--main-color);
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }
}
