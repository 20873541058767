.info__content-wrapper {
    align-items: flex-start;

    & .info__content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 535px;
        margin-right: auto;
    }

    & .info__content {
        margin-top: 55px;

        &:first-of-type {
            margin-top: 40px;
        }
    }

    & .info__content-subheadline {
        margin-top: 0;
    }

    & .info__content-img {
        display: block;
        width: 100%;
        margin-top: 55px;
    }

    & .info__address {
        font-style: inherit;
        line-height: inherit;
        font-size: inherit;
        margin-top: 40px;

        & span {
            display: block;
        }
    }
}
