.aside-container {
    background-color: var(--main-color);
    /*max-width: 100%;
    min-width: 430px;*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    transition: width var(--base-transition-duration) var(--base-transition-ease);

    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        width: 350px;
    }

    @media only screen and (min-width: 1400px) {
        width: 430px;
    }

    & .aside__logo-img-mini {
        display: none;
        border-radius: 50%;
    }

    & .aside__logo-img {
        display: block;
        width: 100%;
    }

    &.is-reset {
        transition: none;

        & * {
            transition: none;
        }
    }

    /*MODIFIER*/
    &:not(.is-mini) {
        & .nav-trigger {
            padding-left: 24px;


        }
    }

    &.is-mini {
        width: 120px;
        padding-left: 0;

        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
            width: 120px;
        }

        @media only screen and (min-width: 1400px) {
            width: 120px;
        }

        &.is-reset {
            transition: none;

            & * {
                transition: none;
            }
        }

        & .aside__logo-link {
            width: 71px;
            height: 71px;
            padding: 0;
            margin: 0;

            & .aside__logo-img-mini {
                transition: box-shadow var(--base-transition-duration) var(--base-transition-ease);
            }

            &:hover {
                & .aside__logo-img-mini {
                    box-shadow: 0 0 20px var(--second-color);
                }
            }
        }

        & .aside__logo-img-mini {
            display: block;
        }

        & .aside__logo-img {
            display: none;
        }

        & .nav-trigger {
            justify-content: center;
        }

        & .nav-trigger__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }

        & .nav-trigger__btn-inner {
            width: 18px;
            height: 18px;

            &::before {
                transform: translateX(-50%) rotateX(0deg);
            }

            & .nav-trigger__btn-text {
                font-size: 0;
                width: 0;
                opacity: 0;
                transform-origin: center center;
                color: var(--white-color);
                transition: width var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease),
                opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
            }
        }

        & .nav {
            & .navbar-link {
                justify-content: center;
                align-items: center;
                margin-left: 0;
                width: 100%;

                & .navbar-link-text {
                    width: 70px;
                    height: 60px;
                    overflow: hidden;
                    padding: 12px 18px;
                    display: block;

                    & .icon-wrapper {
                        left: 50%;
                    }

                    & .navbar-link-text__content {
                        width: 0;
                        opacity: 0;
                        font-size: 0;
                        padding: 0;
                        pointer-events: none;
                        transition: width var(--base-transition-duration) var(--base-transition-ease),
                        opacity calc(var(--base-transition-duration) / 2) var(--base-transition-ease);
                    }
                }
            }
        }

    }
}

.logo-container {
    width: 100%;
    height: var(--height-header);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aside__logo-link {
    display: block;
    padding: 0 20px;
    margin: 0 20px;

    @media only screen and (min-width: 1400px) {
        margin: 0 32px;
    }
}

.nav-trigger {
    background-color: var(--main-color);
    width: 100%;
    height: var(--height-breadcrumb);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-trigger__btn {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: calc(100% - 25px);
    padding: 15px 25px;
    text-align: left;
    transition: width var(--base-transition-duration) var(--base-transition-ease);
}

.nav-trigger__btn-inner {
    position: relative;
    display: block;
    transition: width var(--base-transition-duration) var(--base-transition-ease);
    width: auto;

    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
        background-color: var(--white-color);
    }

    &::before {
        left: 50%;
        top: 0;
        width: 2px;
        height: 18px;
        transform: translateX(-50%) rotateX(-90deg);
    }

    &::after {
        left: 0;
        top: 50%;
        height: 2px;
        width: 18px;
        transform: translateY(-50%);
    }

    & .nav-trigger__btn-text {
        opacity: 1;
        width: auto;
        padding-left: 55px;
        font-size: 16px;
        display: block;
        letter-spacing: 0.27px;
        transition: width var(--base-transition-duration) var(--base-transition-ease),
        opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
    }
}

.nav {
    padding-top: 52px;
    background-color: var(--main-color);
    width: 100%;
}

.navbar {
    list-style: none;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.navbar-link {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1200px) {
        margin: 0 0 20px 0;
    }

    @media only screen and (min-width: 1400px) {
        margin: 0 0 20px 0;
    }

    & .navbar-link-text {
        width: 100%;
        min-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;

        &:hover {
            background-color: var(--white-color);
            color: var(--main-color);

            & .icon-wrapper {
                &::before {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }

            & .navbar-link-text__content {
                color: var(--main-color);
            }
        }

        & .icon-wrapper {
            width: 35px;
            height: 35px;
            position: absolute;
            top: 50%;
            left: 35px;
            transform: translate(-50%, -50%);
            margin-right: 0;
            transition: left var(--base-transition-duration) var(--base-transition-ease);

            &::before,
            &::after {
                position: absolute;
                content:'';
                display: block;
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-size: contain;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }

            &::before {
                opacity: 1;
            }

            &::after {
                opacity: 0;
            }
        }

        & .navbar-link-text__content {
            padding: 0 10px 0 80px;
            font-size: 16px;
            letter-spacing: 0.27px;
            color: var(--white-color);
            text-decoration: none;
            width: auto;
            opacity: 1;
            pointer-events: all;
            transition: width var(--base-transition-duration) var(--base-transition-ease),
            opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
        }
    }

    &.active-navbar-link {
        & .navbar-link-text {
            background-color: white;

            & .navbar-link-text__content {
                color: var(--main-color);
            }

            & .icon-wrapper {
                &::before {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &.icon-upload {
        & .icon-wrapper {
            &::before {
                background-image: url("/img/icons/icon-upload.svg");
            }

            &::after {
                background-image: url("/img/icons/icon-upload-red.svg");
            }
        }
    }

    &.icon-download {
        & .icon-wrapper {
            &::before {
                background-image: url("/img/icons/icon-download.svg");
            }

            &::after {
                background-image: url("/img/icons/icon-download-red.svg");
            }
        }
    }

    &.icon-info {
        & .icon-wrapper {
            &::before {
                background-image: url("/img/icons/icon-info.svg");
            }

            &::after {
                background-image: url("/img/icons/icon-info-red.svg");
            }
        }
    }

    &.icon-enum {
        & .icon-wrapper {
            &::before,
            &::after {
                width: 30px;
                height: 26px;
            }

            &::before {
                background-image: url("/img/icons/icon-enum-white.svg");
            }

            &::after {
                background-image: url("/img/icons/icon-enum.svg");
            }
        }
    }
}
