.breadcrumb {
    width: 100%;
    /*padding: 30px 0;*/
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    height: var(--height-breadcrumb);

    & .container {
        align-items: flex-start;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100vw;
        right: 0;
        border-bottom: 1px solid var(--border-color);
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    & ul {
        margin: 0;
        padding: 0;
        display: flex;
    }

    & li {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        letter-spacing: 0.23px;
        font-weight: 700;
        color: var(--light-text-color);

        &:first-child {
            & span {
                padding-left: 0;
            }
        }

        &:not(:first-child) {
            & a {
                padding-left: 20px;
            }
        }

        & a {
            color: var(--main-color);
            text-decoration: none;
            padding-right: 20px;

            &:hover {
                text-decoration: underline;
            }
        }

        & span {
            padding: 0 20px;
        }

        &:not(:first-child) {
            &:before {
                width: 7px;
                height: 10px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.97 1.076 3.694 3.695L.97 8.466' stroke='%23979797' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
            }
        }
    }
}
