.richtext {
    font-size: 1.6rem;
    line-height: 2.6rem;

    & a {
        color: var(--main-color);
        text-decoration: none;
        font-weight: 700;

        &:hover {
            text-decoration: underline;
        }
    }

    & strong {
        font-weight: 700;
    }

    & .link-button {
        color: var(--white-color);

        &:hover {
            text-decoration: none;
        }
    }

    & ul {
        list-style-type: square;
    }

    & ol,
    & ul {
        padding-left: 1.5em;
    }

    & ol {
        counter-reset: item;

        & > li {
            list-style: none;
            position: relative;

            &::before {
                counter-increment: item;
                content: counters(item, ".") ") ";
                position: absolute;
                left: -1.4em;
            }

            & ol {
                & li {
                    padding-left: 1em;
                }
            }
        }
    }
}
