.header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: var(--height-header);
    background-color: var(--white-color);

    &.is-login {
        height: 106px;

        & .container {
            margin-bottom: 0;
        }
    }

    & .container {
        align-items: flex-end;
        margin-bottom: auto;
    }
}

.header__col {
    margin-left: auto;
}

.header__logo {
    max-width: 100%;
    height: 68px;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}

.header__meta {
    padding: 0;
    margin: 1rem 0 4rem 0;
    display: flex;
}

.header__meta-item {
    margin-left: 29px;
}

.header__meta-link {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    padding:0;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    font-family: "Inter", sans-serif;
    letter-spacing: 0;

    &:hover {
        text-decoration: underline;
    }
}

.icon-wrapper {
    width: 19px;
    height: 19px;
    margin-right: 6px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

.icon-logout {
    background-image: url("/img/icons/icon-logout.svg");
}

.icon-light-language {
    background-image: url("/img/icons/icon-light-lang.svg");
}
