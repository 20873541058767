.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.a-i-f-s {
    align-items: flex-start;
}

.j-c-f-s {
    justify-content: flex-start;
}

.m-t-88 {
    margin-top: 88px;
}

.m-b-96 {
    margin-bottom: 96px;
}

.is-hidden {
    display: none;
}

.is-upload-icon {
    width: 24px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M12 0v15.661'/%3E%3Cpath stroke-linecap='square' d='M17.866 9.966 12 15.661 6.133 9.966M1 16.729V21h22v-4.271'/%3E%3C/g%3E%3C/svg%3E");
}
