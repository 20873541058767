.form-upload {
    width: 100%;
    margin: 20px 0 80px 0;
    position: relative;

    & .form-upload__spinner-wrapper {
        position: fixed;
        width: 0;
        height: 0;
        background-color: var(--grey-bg-color);
        pointer-events: none;
        opacity: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transform-origin: center center;
        transition: width var(--base-transition-duration) var(--base-transition-ease),
        height var(--base-transition-duration) var(--base-transition-ease),
        left var(--base-transition-duration) var(--base-transition-ease),
        top var(--base-transition-duration) var(--base-transition-ease),
        transform var(--base-transition-duration) var(--base-transition-ease),
        opacity var(--base-transition-duration) var(--base-transition-ease);

        &.is-shown {
            width: 100vw;
            height: 100vh;
            z-index: 9990;
            opacity: 1;
            left: 0;
            top: 0;
            transform: translate(0,0);
            pointer-events: all;
            transition: width var(--base-transition-duration) var(--base-transition-ease),
            height var(--base-transition-duration) var(--base-transition-ease),
            left var(--base-transition-duration) var(--base-transition-ease),
            top var(--base-transition-duration) var(--base-transition-ease),
            transform var(--base-transition-duration) var(--base-transition-ease),
            opacity calc(var(--base-transition-duration) * 1.8) var(--base-transition-ease);

            & .form-upload__spinner {
                opacity: 1;
                z-index: 9998;
                transform: translate(-50%,-50%);
                transition: opacity calc(var(--base-transition-duration) * 1.5) var(--base-transition-ease),
                transform calc(var(--base-transition-duration) * 15) calc(var(--base-transition-delay) / 1.5) var(--base-transition-ease);
            }
        }

        & .form-upload__spinner {
            pointer-events: none;
            position: fixed;
            background-color: var(--white-color);
            border-radius: 10px;
            display: flex;
            width: calc(100vw - 60%);
            height: auto;
            padding: 40px 80px 60px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-40%);
            box-shadow: 0 12px 44px var(--transparent-black-color);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
        }

        & .form-upload__spinner-gif {
            width: 88px;
            height: 88px;
            margin-bottom: 10px;
        }

        & .form-upload__spinner-gif-img {
            display: block;
            width: 100%;
            height: 100%;
        }

        & .form-upload__spinner-content {
            font-weight: bold;
            text-align: center;
            margin: 0;
        }
    }
}

.form-upload__box {
    &.is-dropped {
        & .form-upload__file-icon {
            background-image: url("data:image/svg+xml,%3Csvg width='50' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M23.121 2 32 10.95V36c0 .552-.224 1.052-.586 1.414A1.994 1.994 0 0 1 30 38H4a1.994 1.994 0 0 1-1.414-.586A1.994 1.994 0 0 1 2 36V4c0-.552.224-1.052.586-1.414A1.994 1.994 0 0 1 4 2h19.121Z' stroke='%239E1B18' stroke-width='4'/%3E%3Cpath d='M22 5.842 27.593 12H24a1.994 1.994 0 0 1-1.414-.586A1.994 1.994 0 0 1 22 10V5.842Z' stroke='%239E1B18' stroke-width='4'/%3E%3Crect stroke='%23F1F1F1' stroke-width='2' fill='%239E1B18' x='15' y='26' width='34' height='21' rx='2'/%3E%3Ctext font-family='Helvetica' font-size='11.2' fill='%23FFF'%3E%3Ctspan x='19.765' y='40.643'%3ECSV%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E");

        }

        & .form-upload__file-info {
            display: none;
        }
    }

    &.is-error {
        & .form-upload__file-info {
            display: none;
        }

        & .form-upload__file-text {
            color: var(--error-color)
        }
    }
}

.form-upload__drag-area {
    display: flex;
    align-items: center;
    padding: 0 50px;
    height: 155px;
    background-color: var(--grey-bg-color);
    outline: 1px dashed #979797;

    &.drag-over {
        background-color: var(--white-color);
    }
}

.form-upload__input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus-visible + label {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

    &:focus + label {
        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }
        }
    }

}

.form-upload__label {
    display: flex;
    align-items: center;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.33px;
}

.form-upload__file-icon {
    width: 48px;
    height: 48px;
    margin-right: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='3' fill='none' fill-rule='evenodd'%3E%3Cpath d='M20 29.102V3'/%3E%3Cpath stroke-linecap='square' d='M29.6 12.492 20 3l-9.6 9.492M2 30.881V38h36v-7.119'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

.form-upload__file-text {
    margin-right: 10px;
    font-weight: 700;
}

.form-upload__buttons {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.form-upload__button {
    margin-right: 30px;
    cursor: pointer;
    padding: 0;

    & label {
        font-size: 1.6rem;
        font-weight: 700;
        cursor: pointer;
        display: block;
        padding: 1.7rem 2.4rem;
        letter-spacing: 0.27px;
    }
}


.box__uploading,
.box__success,
.box__error {
    display: none;
}
