.content-box {
    display: flex;
    padding: 60px 0;
    width: 100%;
    align-self: flex-start;
    justify-content: center;
    position: relative;

    &.j-c-f-s {
        justify-content: flex-start;
    }

    &:not(.is-without-border) {
        &:before {
            content: '';
            width: 100%;
            left: 0;
            top: 0;
            position: absolute;
            border-top: 1px solid var(--border-color);
        }
    }

    & .link-button {
        margin-top: 15px;
    }
}
