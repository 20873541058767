.footer {
    display: flex;
    margin-top: auto;
    background-color: var(--second-color);
    border-top: 1px solid var(--border-color);

    & .container {
        max-width: 782px;
        flex-direction: row;
        margin: 0 auto;
        padding: 0 20px;

        @media only screen and (min-width: 1200px) {
            padding: 0;
        }
    }
}

.footer__col {
    width: 50%;
    height: 100%;
    padding: 5% 20px;

    @media only screen and (min-width: 1200px) {
        padding: 5% 0;
    }
}

.footer__list-item {
    margin-top: 2rem;
}

.footer__col:first-child {
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    padding: 5% 20px 5% 0;

    @media only screen and (min-width: 1200px) {
        padding: 5% 0;
    }
}

.footer__col:last-child {
    @media only screen and (min-width: 1200px) {
        padding: 5% 0 5% 9rem;
    }
}

.footer__headline {
    color: var(--white-color);
    font-size: 1.8rem;
    line-height: 3.1rem;
}

.footer__list {
    padding: 0;
    margin: 0;
    color: inherit;

    &.is-copy {
        padding-left: 2rem;
    }
}

.footer__list-link {
    color: var(--white-color);
    text-decoration: none;
    font-size: 1.4rem;
    letter-spacing: 0.23px;
    font-weight: 500;

    &:hover {
        border-bottom: 0.125em solid rgba(255, 255, 255, 0.7);
    }
}

