body {
    padding: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
