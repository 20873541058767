.is-btn {
    display: inline-block;
    background-color: var(--main-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    font-size: 1.6rem;
    color: #fff;
    padding: 1.4rem 2.4rem;
    letter-spacing: 0.27px;
    text-align: center;
    outline: none;
    font-weight: 700;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

.is-outline-btn {
    padding: 1.7rem 2.4rem;
    background-color: var(--white-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    color: var(--main-color);
}

.is-icon-btn {
    display: flex;
    align-items: center;
}

.is-btn-icon {
    margin-right: 23px;
}
