:root {
    --main-color: #9e1b18;
    --second-color: #6e0200;
    --secondary-text-color: #494949;
    --light-text-color: #1a1a1a;
    --input-background-color: #f5f5f5;
    --input-bottom-border-color: #000;
    --border-color: #d8d8d8;
    --white-color: #fff;
    --black-color: #000;
    --transparent-black-color: rgba(0,0,0,0.12549);
    --transparent-fourty-black-color: rgba(0,0,0,0.25098);
    --success-color: #40853f;
    --error-color: #a60700;
    --grey-bg-color: #f1f1f1;
    --blue-focus-color: #0000ff;
    --height-header: 183px;
    --height-breadcrumb: 71px;
    --base-spacing: 30px;
    --base-transition-duration: 200ms;
    --base-transition-delay: 400ms;
    --base-transition-ease: ease;
}

* {
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

ol,
ul {
    list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.headline {
    width: 100%;
    margin: 60px 0 5px 0;
    font-size: 3rem;
    line-height: 3.6rem;
}

button {
    background: var(--main-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    border: none;
    font-size: 1.6rem;
    color: var(--white-color);
    padding: 1.8rem 2.4rem;
    letter-spacing: 0.27px;
    text-align: center;
    outline: none;
    font-weight: 700
}

button:disabled {
        opacity: 0.6
    }

button:disabled:hover {
            cursor: not-allowed;
        }

button:hover {
        cursor: pointer;
    }

button.focus-visible, a.focus-visible, input.focus-visible, textarea.focus-visible, select.focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

button:focus-visible, a:focus-visible, input:focus-visible, textarea:focus-visible, select:focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

@media not all and (min-resolution: .001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {

button:focus, a:focus, input:focus, textarea:focus, select:focus {
                outline: 1px solid var(--main-color);
                outline-offset: 2px
    }
            }
        }

form input.focus-visible, form select.focus-visible, form textarea.focus-visible {
            box-shadow: inset 1px 1px 0 black, inset -1px 0 0 black;
            outline: none;
        }

form input:focus-visible, form select:focus-visible, form textarea:focus-visible {
            box-shadow: inset 1px 1px 0 black, inset -1px 0 0 black;
            outline: none;
        }

@media not all and (min-resolution: .001dpcm) {
                @supports (-webkit-appearance:none) and (stroke-color:transparent) {

form input:focus, form select:focus, form textarea:focus {
                    box-shadow: inset 1px 1px 0 black, inset -1px 0 0 black;
                    outline: none
        }
                }
            }

aside button.focus-visible, aside a.focus-visible, footer button.focus-visible, footer a.focus-visible {
            outline: 1px solid var(--white-color);
            outline-offset: 2px;
        }

aside button:focus-visible, aside a:focus-visible, footer button:focus-visible, footer a:focus-visible {
            outline: 1px solid var(--white-color);
            outline-offset: 2px;
        }

@media not all and (min-resolution: .001dpcm) {
                @supports (-webkit-appearance:none) and (stroke-color:transparent) {

aside button:focus, aside a:focus, footer button:focus, footer a:focus {
                    outline: 1px solid var(--white-color);
                    outline-offset: 2px
        }
                }
            }

sup {
    vertical-align: top;
    position: relative;
    top: -0.3em;
}

/*.layout {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 184px auto auto;
    grid-template-areas:
        "nav-trigger header"
        "nav content"
        "nav footer";
}*/

.layout {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: var(--header-height) auto auto;
    grid-template-areas:
        "aside-container header"
        "aside-container content"
        "aside-container footer";
}

.layout-login {
    display: grid;
    background-color: var(--main-color);
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "header"
        "content"
        "footer"
}

.layout-login .container {
        max-width: 960px;
        flex-direction: row
    }

@media screen and (min-width: 1400px) {

.layout-login .container {
            max-width: 1180px
    }
        }

.login-content {
    align-self: flex-start;
    padding-top: 70px;
    height: 100%;
}

.login-headline {
    font-size: 4.5rem;
    line-height: 5.5rem;
    color: var(--white-color);
    margin-bottom: 15px;
    margin-top: 0
}

@media screen and (min-width: 1200px) {

.login-headline {
        font-size: 5.5rem;
        line-height: 6.5rem;
        margin-bottom: 25px;
        margin-top: 0
}
    }

.login-content__article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 140px);
}

.login-logo {
    max-width: 325px
}

@media screen and (min-width: 1200px) {

.login-logo {
        margin-left: -80px
}
    }

.login__description-text {
    color: var(--white-color);
    width: 100%;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0
}

@media screen and (min-width: 1200px) {

.login__description-text {
        font-size: 1.6rem;
        line-height: 2.6rem
}
    }

.aside-container {
    grid-area: aside-container;
}

/*.nav-trigger {
    grid-area: nav-trigger;
}

.nav {
    grid-area: nav;
}*/

.header {
    grid-area: header;
}

.main {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 500px
}

@media only screen and (max-width: 1100px) {

.main > .container {
            padding: 0 20px
    }
        }

.bg-grey {
    background-color: #f2f2f2;
}

.footer {
    grid-area: footer;
}

.bar-left {
    grid-area: bar-left;
}

.bar-right {
    grid-area: bar-right;
}

.container {
    width: 100%;
    max-width: 782px;
    margin: 0 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.container.is-2-col {
        flex-direction: row;
        width: 100%;
        justify-content: space-between
    }

.container.is-2-col .richtext {
            width: 345px;
            flex-shrink: 0;
        }

.container.is-2-col h4 {
            margin: 0;
        }

.container.is-2-col p {
            margin-bottom: 0;
        }

.container.is-2-col .link-button {
            margin-top: auto;
        }

body {
    padding: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.richtext {
    font-size: 1.6rem;
    line-height: 2.6rem
}
.richtext a {
        color: var(--main-color);
        text-decoration: none;
        font-weight: 700
    }
.richtext a:hover {
            text-decoration: underline;
        }
.richtext strong {
        font-weight: 700;
    }
.richtext .link-button {
        color: var(--white-color)
    }
.richtext .link-button:hover {
            text-decoration: none;
        }
.richtext ul {
        list-style-type: square;
    }
.richtext ol,
    .richtext ul {
        padding-left: 1.5em;
    }
.richtext ol {
        counter-reset: item
    }
.richtext ol > li {
            list-style: none;
            position: relative
        }
.richtext ol > li::before {
                counter-increment: item;
                content: counters(item, ".") ") ";
                position: absolute;
                left: -1.4em;
            }
.richtext ol > li ol li {
                    padding-left: 1em;
                }

.is-btn {
    display: inline-block;
    background-color: var(--main-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    font-size: 1.6rem;
    color: #fff;
    padding: 1.4rem 2.4rem;
    letter-spacing: 0.27px;
    text-align: center;
    outline: none;
    font-weight: 700;
    text-decoration: none
}

.is-btn:hover {
        cursor: pointer;
    }

.is-outline-btn {
    padding: 1.7rem 2.4rem;
    background-color: var(--white-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    color: var(--main-color);
}

.is-icon-btn {
    display: flex;
    align-items: center;
}

.is-btn-icon {
    margin-right: 23px;
}

.breadcrumb {
    width: 100%;
    /*padding: 30px 0;*/
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
    height: var(--height-breadcrumb)
}
.breadcrumb .container {
        align-items: flex-start;
    }
.breadcrumb:before,
    .breadcrumb:after {
        content: "";
        position: absolute;
        width: 100vw;
        right: 0;
        border-bottom: 1px solid var(--border-color);
    }
.breadcrumb:before {
        top: 0;
    }
.breadcrumb:after {
        bottom: 0;
    }
.breadcrumb ul {
        margin: 0;
        padding: 0;
        display: flex;
    }
.breadcrumb li {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        letter-spacing: 0.23px;
        font-weight: 700;
        color: var(--light-text-color)
    }
.breadcrumb li:first-child span {
                padding-left: 0;
            }
.breadcrumb li:not(:first-child) a {
                padding-left: 20px;
            }
.breadcrumb li a {
            color: var(--main-color);
            text-decoration: none;
            padding-right: 20px
        }
.breadcrumb li a:hover {
                text-decoration: underline;
            }
.breadcrumb li span {
            padding: 0 20px;
        }
.breadcrumb li:not(:first-child):before {
                width: 7px;
                height: 10px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.97 1.076 3.694 3.695L.97 8.466' stroke='%23979797' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
            }

input,
select {
    background: var(--input-background-color);
    font-size: 16px;
    color: #000;
    letter-spacing: 0.27px;
    padding: 1.9rem 2.4rem 1.9rem 2.4rem;
    border: none;
    border-bottom: 1px solid var(--input-bottom-border-color);
    outline: none;
    border-radius: 0;
    width: 100%
}

input::-moz-placeholder, select::-moz-placeholder {
        font-size: 16px;
        color: #000;
        letter-spacing: 0.27px;
    }

input:-ms-input-placeholder, select:-ms-input-placeholder {
        font-size: 16px;
        color: #000;
        letter-spacing: 0.27px;
    }

input::placeholder, select::placeholder {
        font-size: 16px;
        color: #000;
        letter-spacing: 0.27px;
    }

input:user-invalid, select:user-invalid {
        color: red;
        border-bottom: 1px solid red;
    }

.form-invalid {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--white-color);
    padding: 10px 15px;
    text-align: center;
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: var(--black-color);
    box-shadow: 0 2px 4px grey;
    border-radius: 4px;
    width: 100%;
    z-index: 9998;
}

.is-date {
    position: relative
}

.is-date::before {
        content: '';
        color: transparent;
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        right: 15px;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h22v22H0z'/%3E%3Cpath d='M16.507 8.267h1.373V9.64h-1.373V8.267Zm1.373 5.493h-1.373v1.373h1.373V13.76Zm-1.373-2.747h1.373v1.374h-1.373v-1.374ZM13.76 8.267h-1.373V9.64h1.373V8.267Zm-1.373 8.24h1.373v1.373h-1.373v-1.373Zm1.373-2.747h-1.373v1.373h1.373V13.76Zm-1.373-2.747h1.373v1.374h-1.373v-1.374ZM9.64 8.267H8.267V9.64H9.64V8.267Zm-1.373 8.24H9.64v1.373H8.267v-1.373ZM9.64 13.76H8.267v1.373H9.64V13.76Zm-1.373-2.747H9.64v1.374H8.267v-1.374ZM5.52 16.507H4.147v1.373H5.52v-1.373ZM4.147 13.76H5.52v1.373H4.147V13.76Zm1.373-2.747H4.147v1.374H5.52v-1.374ZM19.253 5.52H2.773V4.147h16.48V5.52Zm0 13.733H2.773V6.893h16.48v12.36Zm.687-16.48h-2.06V1.4h-1.373v1.373H5.52V1.4H4.147v1.373h-2.06L1.4 3.46v16.48l.687.687H19.94l.687-.687V3.46l-.687-.687Z' fill='%239E1B18'/%3E%3C/g%3E%3C/svg%3E");
    }

.is-date input {
        padding-right: 1.5rem
    }

.is-date input::-webkit-calendar-picker-indicator {
            color: transparent;
            background: none;
            width: 28px;
            height: 28px;
            z-index: 1;
        }

.form-row {
    margin-bottom: 35px;
    display: flex;
}

.alert {
    position: absolute;
    display: flex;
    min-width: 420px;
    max-width: 560px;
    background-color: var(--white-color);
    font-size: 16px;
    color: #000;
    line-height: 26px;
    font-weight: 500;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.27);
}

.alert-text {
    padding: 25px 25px 25px 39px;
}

.alert-icon {
    position: relative;
    width: 85px;
    flex-shrink: 0
}

.alert-icon::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-size: contain;
        background-repeat: no-repeat;
    }

.alert-success {
    top: 0
}

.alert-success .alert-icon {
        background-color: var(--success-color)
    }

.alert-success .alert-icon::before {
            width: 32px;
            height: 32px;
            background-image: url("/img/icons/icon-pass.svg");
        }

.alert-error {
    bottom: 0
}

@media screen and (min-width: 1200px) {

.alert-error {
        bottom: 35px
}
    }

.alert-error .alert-icon {
        background-color: var(--second-color)
    }

.alert-error .alert-icon::before {
            width: 28px;
            height: 28px;
            background-image: url("/img/icons/icon-warning.svg");
        }

.form-head {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--white-color);
    background-color: var(--second-color)
}

.form-head .container {
        align-items: flex-start;
    }

.form-required-legend {
    margin: 40px 0 0;
    width: 100%;
    padding: 24px 64px;
    background-color: var(--white-color);
    border-bottom: 1px solid var(--border-color);
}

.form-head__error-block {
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 25px;
    margin: 8px 8px 70px 8px;
    color: var(--black-color);
    border-radius: 2px;
    background-color: var(--white-color)
}

.form-head__error-block:after {
        content: '';
        position: absolute;
        left: -8px;
        right: -8px;
        top: -8px;
        bottom: -8px;
        border-radius: 2px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23fff' stroke-width='4' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    }

.form-head__error-block + .form-head__error-block {
        margin-top: -20px;
    }

.form-head__error-block span {
        padding: 0 15px;
        font-size: 1.4rem;
        line-height: 2rem;
    }

.form-head__error-icon {
    width: 28%;
    flex-shrink: 0;
    position: relative;
    padding-left: 37px
}

.form-head__error-icon:before {
        content: '';
        left: 0;
        position: absolute;
        width: 25px;
        height: 23px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.156 15.75v-7h2.188v7h-2.188Zm2.188 3.5V17.5h-2.188v1.75h2.188ZM12.25 2.24 2.24 21h19.985L12.25 2.24ZM11.48 0h1.54l11.445 21.455-.77 1.295H.77L0 21.455 11.48 0Z' fill='%239E1B18' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

.form-head__error-link {
    width: 142px;
    padding: 13px 0;
    flex-shrink: 0;
    margin-left: auto;
    text-align: center;
    border-radius: 2px;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.23px;
    border: 2px solid var(--main-color);
    color: var(--main-color);
    z-index: 2
}

.form-head__error-link:hover {
        cursor: pointer;
    }

.form-headline__wrap {
    width: 100%;
    display: flex;
    align-items: baseline
}

.form-headline__wrap .form-headline {
        flex-shrink: 0;
        width: auto;
    }

.form-headline__wrap .form-headline__sm {
        margin-left: auto;
    }

.form-headline {
    width: 95%;
    margin: 0;
    padding: 50px 0;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
}

.form-headline__sm {
    font-size: 1.8rem;
    line-height: 2.1rem;
}

.form-group-wrapper {
    width: 100%;
    padding: 24px 64px 50px 64px;
    background-color: white;
    border-radius: 2px;
    margin: 40px 0 0 0
}

.form-group-wrapper.is-empty .form-group-legend {
            border-color: transparent;
            margin-bottom: 0;
        }

.form-group-wrapper.is-empty .form-group-empty-text {
            display: flex;
        }

[aria-labelledby="location-of-operation-unit"] {
    margin-top: 0;
}

.form-group-legend {
    color: var(--black-color);
    padding: 0 64px 24px 64px;
    margin: 0 -64px 50px -64px;
    border-bottom: solid #d8d8d8 1px;
    font-size: 2rem;
    line-height: 3.1rem;
    font-weight: 700;
}

.form-group-empty-text {
    display: none;
    padding: 75px 0;
    margin: 0 -64px -50px -64px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px dot-dash #9F9F9F;
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.6rem;
    color: #6f6f6f;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%239f9f9f' stroke-width='2' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.form-element {
    display: flex;
    flex-direction: column;
    padding-bottom: 36px
}

.form-element.is-with-tooltip {
        flex-flow: row wrap
    }

.form-element.is-with-tooltip .form-input-wrap,
        .form-element.is-with-tooltip .form-select {
            flex: 1 100%;
        }

.form-element label {
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: 0.27px;
    }

.form-element .is-error {
        color: var(--error-color);
    }

.form-input-wrap,
.form-select {
    position: relative;
}

.form-select {
    display: flex;
    position: relative
}

.form-select:after {
        content: "";
        width: 19px;
        height: 12px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        z-index: 3;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 2 9.5 9 2 2' stroke='%239E1B18' stroke-width='4' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
        pointer-events: none;
    }

.form-select select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-right: 4rem;
        text-overflow: ellipsis
    }

.form-select select:hover {
            cursor: pointer;
        }

.form-error {
    position: relative;
    color: var(--error-color);
    display: flex;
    margin-top: 15px;
    line-height: 2.1rem
}

.form-error:before {
        content: '';
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-right: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h40v40H0z'/%3E%3Cpath d='M18.438 25V15h3.125v10h-3.125Zm3.125 5v-2.5h-3.125V30h3.125ZM20 5.7 5.7 32.5h28.55L20 5.7Zm-1.1-3.2h2.2l16.35 30.65-1.1 1.85H3.6l-1.1-1.85L18.9 2.5Z' fill='%23A60700'/%3E%3C/g%3E%3C/svg%3E");
    }

.form-grid-row {
    display: grid;
    grid-gap: 30px;
    align-items: flex-start
}

.form-grid-row.gta-1-1 {
        grid-template-areas: "a b";
        grid-auto-columns: 1fr 1fr;
    }

.form-grid-row.gta-1-3 {
        grid-template-areas: "a b";
        grid-auto-columns: 1fr 3fr;
    }

.form-grid-row.gta-3-1 {
        grid-template-areas: "a b";
        grid-auto-columns: 3fr 1fr;
    }

.radio-list-label {
    margin: 0 0 30px 0;
    font-weight: 700
}

.radio-list-label + .form-tooltip {
        margin-left: 10px;
        margin-top: -6px;
    }

.radio-list {
    display: flex;
    flex: 1 100%;
    padding: 0;
    margin: 0;
}

.radio-wrapper {
    min-height: 32px;
    margin-right: 20px;
    display: flex;
    align-items: center
}

.radio-wrapper label {
        position: relative;
        padding: 6px 20px 6px 40px;
        font-weight: 400
    }

.radio-wrapper label:before {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border: #9e1b18 2px solid;
            border-radius: 50%;
            position: absolute;
            content: "";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

.radio-wrapper label:after {
            width: 18px;
            height: 18px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            content: "";
            left: 16px;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: background-color 250ms 17ms ease;
        }

.radio-wrapper input.focus-visible:checked + label {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }

.radio-wrapper input:focus-visible:checked + label {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }

@media not all and (min-resolution:.001dpcm) {
                    @supports (-webkit-appearance:none) and (stroke-color:transparent) {

.radio-wrapper input:focus:checked + label {
                        outline: 1px solid var(--main-color);
                        outline-offset: 2px
            }
                    }
                }

.radio-wrapper input:checked + label:after {
                background-color: #9e1b18;
            }

.hide {
    display: none;
}

.form-login {
    width: 100%;
    max-width: 304px;
    /*margin-top: 36px;
    margin-bottom: 80px;*/
    margin: 0;
    z-index: 1
}

.form-login input {
        width: 100%;
    }

.form-login button {
        width: 100%;
        border: 4px solid var(--white-color);
    }

.form-login__shape {
    position: relative;
    margin: 114px 0 70px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 520px;
    height: 520px;
    flex-shrink: 0
}

.form-login__shape::before {
        position: absolute;
        left: 8rem;
        content: '';
        width: 100%;
        height: 100%;
        background: url("/img/icons/login-shape.svg") no-repeat;
        background-size: contain;
    }

@media screen and (min-width: 1200px) {

.form-login__shape {
        width: 600px;
        height: 600px
}
    }

@media screen and (min-width: 1400px) {

.form-login__shape {
        width: 628px;
        height: 628px
}
    }

/* ---------------------------------------------------
Form status(form success and errors pages)
--------------------------------------------------- */

.form-status {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 30px 25px;
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
    color: var(--white-color)
}

.form-status.is-with-steps {
        padding: 0
    }

.form-status.is-with-steps.is-success .form-status__step1 {
                width: 43%;
                margin-right: 0;
                flex-shrink: 0
            }

.form-status.is-with-steps.is-success .form-status__step1:before,
                .form-status.is-with-steps.is-success .form-status__step1:after {
                    border: 1px solid;
                    height: 50%;
                }

.form-status.is-with-steps.is-success .form-status__step1:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: -30px;
                    transform: skewX(-28deg);
                }

.form-status.is-with-steps.is-success .form-status__step1:after {
                    transform: skewX(28deg);
                }

.form-status.is-with-steps.is-success .form-status__step2 {
                width: 50%;
                margin-left: auto;
                display: flex;
                color: var(--white-color);
            }

.form-status.is-success {
        background-color: var(--success-color)
    }

.form-status.is-success .form-status__icon {
            width: 40px;
            height: 40px;
            background-image: url("/img/icons/icon-pass.svg");
            background-size: contain;
        }

.form-status.is-error {
        background-color: var(--second-color)
    }

.form-status.is-error .form-status__step1 {
            background-color: var(--second-color);
        }

.form-status.is-error .form-status__icon {
            width: 34px;
            height: 34px;
            background-image: url("/img/icons/icon-warning.svg");
        }

.form-status.is-error .form-status__text {
            width: auto;
        }

.form-status.is-grey {
        background-color: var(--grey-bg-color);
    }

.form-status__step1,
.form-status__step2 {
    padding: 30px 25px;
}

.form-status__step2 {
    width: 33%;
    color: var(--black-color);
}

.form-status__step1 {
    width: 43%;
    margin-right: 87px;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--white-color)
}

.form-status__step1:after {
        content: '';
        position: absolute;
        right: -30px;
        top: 0;
        width: 0;
        height: 0;
        border-width: 56px 0 56px 30px;
        border-color: transparent transparent transparent var(--second-color);
        border-style: solid;
    }

.form-status__icon {
    margin-right: 20px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center;
}

.form-status__text {
    width: 47%;
}

.form-status__list {
    margin-top: 40px;
    padding: 30px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%239f9f9f' stroke-width='4' stroke-dasharray='3%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.form-status__list-item {
    display: flex
}

.form-status__list-item:not(:first-child) {
        padding-top: 20px;
        margin-top: 30px;
        border-top: 1px solid var(--border-color);
    }

.form-status__list-item-text {
    position: relative;
    padding-left: 50px;
    margin-right: 45px;
    width: 160px;
    flex-shrink: 0;
    font-size: 2rem;
    line-height: 3.1rem;
    font-weight: 700
}

.form-status__list-item-text:before {
        content: '';
        position: absolute;
        left: 0;
        width: 25px;
        height: 23px;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.156 15.75v-7h2.188v7h-2.188Zm2.188 3.5V17.5h-2.188v1.75h2.188ZM12.25 2.24 2.24 21h19.985L12.25 2.24ZM11.48 0h1.54l11.445 21.455-.77 1.295H.77L0 21.455 11.48 0Z' fill='%239E1B18' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

.form-status__list-sub {
    padding: 0;
}

.form-status__list-sub-item {
    display: flex
}

.form-status__list-sub-item:not(:first-child) {
        margin-top: 17px;
    }

.form-status__list-sub-item strong {
        width: 180px;
        flex-shrink: 0;
        margin-right: 55px;
    }

.form-tooltip {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: auto;
    position: relative;
    display: inline-block
}

.form-tooltip:before {
        content: '';
        width: 40px;
        height: 45px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

.form-tooltip:hover,
    .form-tooltip:focus {
        cursor: help;
        pointer-events: auto
    }

.form-tooltip:hover .form-tooltip__content, .form-tooltip:focus .form-tooltip__content {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0px) translateX(-50%);
        }

.form-tooltip:hover .tooltip-icon circle, .form-tooltip:focus .tooltip-icon circle {
                fill: var(--main-color);
            }

.form-tooltip:hover .tooltip-icon text, .form-tooltip:focus .tooltip-icon text {
                fill: var(--white-color);
            }

.form-tooltip.focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

.form-tooltip:focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

@media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {

.form-tooltip:focus {
                outline: 1px solid var(--main-color);
                outline-offset: 2px
    }
            }
        }

.tooltip-icon {
    position: absolute;
}

.form-tooltip__content {
    position: absolute;
    bottom: 100%;
    left: 50%;
    min-width: 300px;
    max-width: 350px;
    padding: 30px 26px;
    background-color: white;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.27px;
    border-radius: 2px;
    box-shadow: 0 12px 44px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 22px;
    z-index: 99;
    pointer-events: none;
    transform: translateY(10px) translateX(-50%);
    opacity: 0;
    transition: all 0.25s ease-out
}

.form-tooltip__content:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 11.5px 0 11.5px;
        border-color: var(--white-color) transparent transparent transparent;
    }

.form-tooltip__content a {
        color: var(--main-color);
        font-weight: 700
    }

.form-tooltip__content a:hover {
            text-decoration: none;
        }

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.a-i-f-s {
    align-items: flex-start;
}

.j-c-f-s {
    justify-content: flex-start;
}

.m-t-88 {
    margin-top: 88px;
}

.m-b-96 {
    margin-bottom: 96px;
}

.is-hidden {
    display: none;
}

.is-upload-icon {
    width: 24px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M12 0v15.661'/%3E%3Cpath stroke-linecap='square' d='M17.866 9.966 12 15.661 6.133 9.966M1 16.729V21h22v-4.271'/%3E%3C/g%3E%3C/svg%3E");
}

.aside-container {
    background-color: var(--main-color);
    /*max-width: 100%;
    min-width: 430px;*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    transition: width var(--base-transition-duration) var(--base-transition-ease)
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

.aside-container {
        width: 350px
}
    }

@media only screen and (min-width: 1400px) {

.aside-container {
        width: 430px
}
    }

.aside-container .aside__logo-img-mini {
        display: none;
        border-radius: 50%;
    }

.aside-container .aside__logo-img {
        display: block;
        width: 100%;
    }

.aside-container.is-reset {
        transition: none
    }

.aside-container.is-reset * {
            transition: none;
        }

.aside-container {

    /*MODIFIER*/
}

.aside-container:not(.is-mini) .nav-trigger {
            padding-left: 24px;


        }

.aside-container.is-mini {
        width: 120px;
        padding-left: 0

    }

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

.aside-container.is-mini {
            width: 120px

    }
        }

@media only screen and (min-width: 1400px) {

.aside-container.is-mini {
            width: 120px

    }
        }

.aside-container.is-mini.is-reset {
            transition: none
        }

.aside-container.is-mini.is-reset * {
                transition: none;
            }

.aside-container.is-mini .aside__logo-link {
            width: 71px;
            height: 71px;
            padding: 0;
            margin: 0
        }

.aside-container.is-mini .aside__logo-link .aside__logo-img-mini {
                transition: box-shadow var(--base-transition-duration) var(--base-transition-ease);
            }

.aside-container.is-mini .aside__logo-link:hover .aside__logo-img-mini {
                    box-shadow: 0 0 20px var(--second-color);
                }

.aside-container.is-mini .aside__logo-img-mini {
            display: block;
        }

.aside-container.is-mini .aside__logo-img {
            display: none;
        }

.aside-container.is-mini .nav-trigger {
            justify-content: center;
        }

.aside-container.is-mini .nav-trigger__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }

.aside-container.is-mini .nav-trigger__btn-inner {
            width: 18px;
            height: 18px
        }

.aside-container.is-mini .nav-trigger__btn-inner::before {
                transform: translateX(-50%) rotateX(0deg);
            }

.aside-container.is-mini .nav-trigger__btn-inner .nav-trigger__btn-text {
                font-size: 0;
                width: 0;
                opacity: 0;
                transform-origin: center center;
                color: var(--white-color);
                transition: width var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease),
                opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
            }

.aside-container.is-mini .nav .navbar-link {
                justify-content: center;
                align-items: center;
                margin-left: 0;
                width: 100%
            }

.aside-container.is-mini .nav .navbar-link .navbar-link-text {
                    width: 70px;
                    height: 60px;
                    overflow: hidden;
                    padding: 12px 18px;
                    display: block
                }

.aside-container.is-mini .nav .navbar-link .navbar-link-text .icon-wrapper {
                        left: 50%;
                    }

.aside-container.is-mini .nav .navbar-link .navbar-link-text .navbar-link-text__content {
                        width: 0;
                        opacity: 0;
                        font-size: 0;
                        padding: 0;
                        pointer-events: none;
                        transition: width var(--base-transition-duration) var(--base-transition-ease),
                        opacity calc(var(--base-transition-duration) / 2) var(--base-transition-ease);
                    }

.logo-container {
    width: 100%;
    height: var(--height-header);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aside__logo-link {
    display: block;
    padding: 0 20px;
    margin: 0 20px
}

@media only screen and (min-width: 1400px) {

.aside__logo-link {
        margin: 0 32px
}
    }

.nav-trigger {
    background-color: var(--main-color);
    width: 100%;
    height: var(--height-breadcrumb);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-trigger__btn {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: calc(100% - 25px);
    padding: 15px 25px;
    text-align: left;
    transition: width var(--base-transition-duration) var(--base-transition-ease);
}

.nav-trigger__btn-inner {
    position: relative;
    display: block;
    transition: width var(--base-transition-duration) var(--base-transition-ease);
    width: auto
}

.nav-trigger__btn-inner::before,
    .nav-trigger__btn-inner::after {
        position: absolute;
        content: '';
        display: block;
        background-color: var(--white-color);
    }

.nav-trigger__btn-inner::before {
        left: 50%;
        top: 0;
        width: 2px;
        height: 18px;
        transform: translateX(-50%) rotateX(-90deg);
    }

.nav-trigger__btn-inner::after {
        left: 0;
        top: 50%;
        height: 2px;
        width: 18px;
        transform: translateY(-50%);
    }

.nav-trigger__btn-inner .nav-trigger__btn-text {
        opacity: 1;
        width: auto;
        padding-left: 55px;
        font-size: 16px;
        display: block;
        letter-spacing: 0.27px;
        transition: width var(--base-transition-duration) var(--base-transition-ease),
        opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
    }

.nav {
    padding-top: 52px;
    background-color: var(--main-color);
    width: 100%;
}

.navbar {
    list-style: none;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.navbar-link {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative
}

.navbar-link:last-child {
        margin-bottom: 0;
    }

@media only screen and (min-width: 1200px) {

.navbar-link {
        margin: 0 0 20px 0
}
    }

@media only screen and (min-width: 1400px) {

.navbar-link {
        margin: 0 0 20px 0
}
    }

.navbar-link .navbar-link-text {
        width: 100%;
        min-height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none
    }

.navbar-link .navbar-link-text:hover {
            background-color: var(--white-color);
            color: var(--main-color)
        }

.navbar-link .navbar-link-text:hover .icon-wrapper::before {
                    opacity: 0;
                }

.navbar-link .navbar-link-text:hover .icon-wrapper::after {
                    opacity: 1;
                }

.navbar-link .navbar-link-text:hover .navbar-link-text__content {
                color: var(--main-color);
            }

.navbar-link .navbar-link-text .icon-wrapper {
            width: 35px;
            height: 35px;
            position: absolute;
            top: 50%;
            left: 35px;
            transform: translate(-50%, -50%);
            margin-right: 0;
            transition: left var(--base-transition-duration) var(--base-transition-ease)
        }

.navbar-link .navbar-link-text .icon-wrapper::before,
            .navbar-link .navbar-link-text .icon-wrapper::after {
                position: absolute;
                content:'';
                display: block;
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-size: contain;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }

.navbar-link .navbar-link-text .icon-wrapper::before {
                opacity: 1;
            }

.navbar-link .navbar-link-text .icon-wrapper::after {
                opacity: 0;
            }

.navbar-link .navbar-link-text .navbar-link-text__content {
            padding: 0 10px 0 80px;
            font-size: 16px;
            letter-spacing: 0.27px;
            color: var(--white-color);
            text-decoration: none;
            width: auto;
            opacity: 1;
            pointer-events: all;
            transition: width var(--base-transition-duration) var(--base-transition-ease),
            opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
        }

.navbar-link.active-navbar-link .navbar-link-text {
            background-color: white
        }

.navbar-link.active-navbar-link .navbar-link-text .navbar-link-text__content {
                color: var(--main-color);
            }

.navbar-link.active-navbar-link .navbar-link-text .icon-wrapper::before {
                    opacity: 0;
                }

.navbar-link.active-navbar-link .navbar-link-text .icon-wrapper::after {
                    opacity: 1;
                }

.navbar-link.icon-upload .icon-wrapper::before {
                background-image: url("/img/icons/icon-upload.svg");
            }

.navbar-link.icon-upload .icon-wrapper::after {
                background-image: url("/img/icons/icon-upload-red.svg");
            }

.navbar-link.icon-download .icon-wrapper::before {
                background-image: url("/img/icons/icon-download.svg");
            }

.navbar-link.icon-download .icon-wrapper::after {
                background-image: url("/img/icons/icon-download-red.svg");
            }

.navbar-link.icon-info .icon-wrapper::before {
                background-image: url("/img/icons/icon-info.svg");
            }

.navbar-link.icon-info .icon-wrapper::after {
                background-image: url("/img/icons/icon-info-red.svg");
            }

.navbar-link.icon-enum .icon-wrapper::before,
            .navbar-link.icon-enum .icon-wrapper::after {
                width: 30px;
                height: 26px;
            }

.navbar-link.icon-enum .icon-wrapper::before {
                background-image: url("/img/icons/icon-enum-white.svg");
            }

.navbar-link.icon-enum .icon-wrapper::after {
                background-image: url("/img/icons/icon-enum.svg");
            }

.header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: var(--height-header);
    background-color: var(--white-color)
}

.header.is-login {
        height: 106px
    }

.header.is-login .container {
            margin-bottom: 0;
        }

.header .container {
        align-items: flex-end;
        margin-bottom: auto;
    }

.header__col {
    margin-left: auto;
}

.header__logo {
    max-width: 100%;
    height: 68px;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
}

.header__meta {
    padding: 0;
    margin: 1rem 0 4rem 0;
    display: flex;
}

.header__meta-item {
    margin-left: 29px;
}

.header__meta-link {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    padding:0;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    font-family: "Inter", sans-serif;
    letter-spacing: 0
}

.header__meta-link:hover {
        text-decoration: underline;
    }

.icon-wrapper {
    width: 19px;
    height: 19px;
    margin-right: 6px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

.icon-logout {
    background-image: url("/img/icons/icon-logout.svg");
}

.icon-light-language {
    background-image: url("/img/icons/icon-light-lang.svg");
}

.footer {
    display: flex;
    margin-top: auto;
    background-color: var(--second-color);
    border-top: 1px solid var(--border-color)
}

.footer .container {
        max-width: 782px;
        flex-direction: row;
        margin: 0 auto;
        padding: 0 20px
    }

@media only screen and (min-width: 1200px) {

.footer .container {
            padding: 0
    }
        }

.footer__col {
    width: 50%;
    height: 100%;
    padding: 5% 20px
}

@media only screen and (min-width: 1200px) {

.footer__col {
        padding: 5% 0
}
    }

.footer__list-item {
    margin-top: 2rem;
}

.footer__col:first-child {
    margin-right: auto;
    border-right: 1px solid var(--border-color);
    padding: 5% 20px 5% 0
}

@media only screen and (min-width: 1200px) {

.footer__col:first-child {
        padding: 5% 0
}
    }

@media only screen and (min-width: 1200px) {

.footer__col:last-child {
        padding: 5% 0 5% 9rem
}
    }

.footer__headline {
    color: var(--white-color);
    font-size: 1.8rem;
    line-height: 3.1rem;
}

.footer__list {
    padding: 0;
    margin: 0;
    color: inherit
}

.footer__list.is-copy {
        padding-left: 2rem;
    }

.footer__list-link {
    color: var(--white-color);
    text-decoration: none;
    font-size: 1.4rem;
    letter-spacing: 0.23px;
    font-weight: 500
}

.footer__list-link:hover {
        border-bottom: 0.125em solid rgba(255, 255, 255, 0.7);
    }


.form-upload {
    width: 100%;
    margin: 20px 0 80px 0;
    position: relative
}

.form-upload .form-upload__spinner-wrapper {
        position: fixed;
        width: 0;
        height: 0;
        background-color: var(--grey-bg-color);
        pointer-events: none;
        opacity: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transform-origin: center center;
        transition: width var(--base-transition-duration) var(--base-transition-ease),
        height var(--base-transition-duration) var(--base-transition-ease),
        left var(--base-transition-duration) var(--base-transition-ease),
        top var(--base-transition-duration) var(--base-transition-ease),
        transform var(--base-transition-duration) var(--base-transition-ease),
        opacity var(--base-transition-duration) var(--base-transition-ease)
    }

.form-upload .form-upload__spinner-wrapper.is-shown {
            width: 100vw;
            height: 100vh;
            z-index: 9990;
            opacity: 1;
            left: 0;
            top: 0;
            transform: translate(0,0);
            pointer-events: all;
            transition: width var(--base-transition-duration) var(--base-transition-ease),
            height var(--base-transition-duration) var(--base-transition-ease),
            left var(--base-transition-duration) var(--base-transition-ease),
            top var(--base-transition-duration) var(--base-transition-ease),
            transform var(--base-transition-duration) var(--base-transition-ease),
            opacity calc(var(--base-transition-duration) * 1.8) var(--base-transition-ease)
        }

.form-upload .form-upload__spinner-wrapper.is-shown .form-upload__spinner {
                opacity: 1;
                z-index: 9998;
                transform: translate(-50%,-50%);
                transition: opacity calc(var(--base-transition-duration) * 1.5) var(--base-transition-ease),
                transform calc(var(--base-transition-duration) * 15) calc(var(--base-transition-delay) / 1.5) var(--base-transition-ease);
            }

.form-upload .form-upload__spinner-wrapper .form-upload__spinner {
            pointer-events: none;
            position: fixed;
            background-color: var(--white-color);
            border-radius: 10px;
            display: flex;
            width: calc(100vw - 60%);
            height: auto;
            padding: 40px 80px 60px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-40%);
            box-shadow: 0 12px 44px var(--transparent-black-color);
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity var(--base-transition-duration) var(--base-transition-delay) var(--base-transition-ease);
        }

.form-upload .form-upload__spinner-wrapper .form-upload__spinner-gif {
            width: 88px;
            height: 88px;
            margin-bottom: 10px;
        }

.form-upload .form-upload__spinner-wrapper .form-upload__spinner-gif-img {
            display: block;
            width: 100%;
            height: 100%;
        }

.form-upload .form-upload__spinner-wrapper .form-upload__spinner-content {
            font-weight: bold;
            text-align: center;
            margin: 0;
        }

.form-upload__box.is-dropped .form-upload__file-icon {
            background-image: url("data:image/svg+xml,%3Csvg width='50' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M23.121 2 32 10.95V36c0 .552-.224 1.052-.586 1.414A1.994 1.994 0 0 1 30 38H4a1.994 1.994 0 0 1-1.414-.586A1.994 1.994 0 0 1 2 36V4c0-.552.224-1.052.586-1.414A1.994 1.994 0 0 1 4 2h19.121Z' stroke='%239E1B18' stroke-width='4'/%3E%3Cpath d='M22 5.842 27.593 12H24a1.994 1.994 0 0 1-1.414-.586A1.994 1.994 0 0 1 22 10V5.842Z' stroke='%239E1B18' stroke-width='4'/%3E%3Crect stroke='%23F1F1F1' stroke-width='2' fill='%239E1B18' x='15' y='26' width='34' height='21' rx='2'/%3E%3Ctext font-family='Helvetica' font-size='11.2' fill='%23FFF'%3E%3Ctspan x='19.765' y='40.643'%3ECSV%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E");

        }

.form-upload__box.is-dropped .form-upload__file-info {
            display: none;
        }

.form-upload__box.is-error .form-upload__file-info {
            display: none;
        }

.form-upload__box.is-error .form-upload__file-text {
            color: var(--error-color)
        }

.form-upload__drag-area {
    display: flex;
    align-items: center;
    padding: 0 50px;
    height: 155px;
    background-color: var(--grey-bg-color);
    outline: 1px dashed #979797
}

.form-upload__drag-area.drag-over {
        background-color: var(--white-color);
    }

.form-upload__input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1

}

.form-upload__input.focus-visible + label {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

.form-upload__input:focus-visible + label {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

@media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {

.form-upload__input:focus + label {
                outline: 1px solid var(--main-color);
                outline-offset: 2px
    }
            }
        }

.form-upload__label {
    display: flex;
    align-items: center;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.33px;
}

.form-upload__file-icon {
    width: 48px;
    height: 48px;
    margin-right: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='3' fill='none' fill-rule='evenodd'%3E%3Cpath d='M20 29.102V3'/%3E%3Cpath stroke-linecap='square' d='M29.6 12.492 20 3l-9.6 9.492M2 30.881V38h36v-7.119'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

.form-upload__file-text {
    margin-right: 10px;
    font-weight: 700;
}

.form-upload__buttons {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.form-upload__button {
    margin-right: 30px;
    cursor: pointer;
    padding: 0
}

.form-upload__button label {
        font-size: 1.6rem;
        font-weight: 700;
        cursor: pointer;
        display: block;
        padding: 1.7rem 2.4rem;
        letter-spacing: 0.27px;
    }

.box__uploading,
.box__success,
.box__error {
    display: none;
}

.content-box {
    display: flex;
    padding: 60px 0;
    width: 100%;
    align-self: flex-start;
    justify-content: center;
    position: relative
}
.content-box.j-c-f-s {
        justify-content: flex-start;
    }
.content-box:not(.is-without-border):before {
            content: '';
            width: 100%;
            left: 0;
            top: 0;
            position: absolute;
            border-top: 1px solid var(--border-color);
        }
.content-box .link-button {
        margin-top: 15px;
    }

.info__content-wrapper {
    align-items: flex-start
}
.info__content-wrapper .info__content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 535px;
        margin-right: auto;
    }
.info__content-wrapper .info__content {
        margin-top: 55px
    }
.info__content-wrapper .info__content:first-of-type {
            margin-top: 40px;
        }
.info__content-wrapper .info__content-subheadline {
        margin-top: 0;
    }
.info__content-wrapper .info__content-img {
        display: block;
        width: 100%;
        margin-top: 55px;
    }
.info__content-wrapper .info__address {
        font-style: inherit;
        line-height: inherit;
        font-size: inherit;
        margin-top: 40px
    }
.info__content-wrapper .info__address span {
            display: block;
        }



/*# sourceMappingURL=main.css.map */
