* {
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

ol,
ul {
    list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.headline {
    width: 100%;
    margin: 60px 0 5px 0;
    font-size: 3rem;
    line-height: 3.6rem;
}

button {
    background: var(--main-color);
    box-shadow: inset 2px 2px 0 var(--main-color), inset -2px -2px 0 var(--main-color);
    border: none;
    font-size: 1.6rem;
    color: var(--white-color);
    padding: 1.8rem 2.4rem;
    letter-spacing: 0.27px;
    text-align: center;
    outline: none;
    font-weight: 700;

    &:disabled {
        opacity: 0.6;

        &:hover {
            cursor: not-allowed;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

button,
a,
input,
textarea,
select {
    &:focus-visible {
        outline: 1px solid var(--main-color);
        outline-offset: 2px;
    }

    &:focus {
        @media not all and (min-resolution: .001dpcm) {
            @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                outline: 1px solid var(--main-color);
                outline-offset: 2px;
            }
        }
    }
}


form {
    & input,
    & select,
    & textarea {
        &:focus-visible {
            box-shadow: inset 1px 1px 0 black, inset -1px 0 0 black;
            outline: none;
        }

        &:focus {
            @media not all and (min-resolution: .001dpcm) {
                @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                    box-shadow: inset 1px 1px 0 black, inset -1px 0 0 black;
                    outline: none;
                }
            }
        }
    }
}

aside,
footer {
    & button,
    & a {
        &:focus-visible {
            outline: 1px solid var(--white-color);
            outline-offset: 2px;
        }

        &:focus {
            @media not all and (min-resolution: .001dpcm) {
                @supports (-webkit-appearance:none) and (stroke-color:transparent) {
                    outline: 1px solid var(--white-color);
                    outline-offset: 2px;
                }
            }
        }
    }
}

sup {
    vertical-align: top;
    position: relative;
    top: -0.3em;
}
