/*.layout {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 184px auto auto;
    grid-template-areas:
        "nav-trigger header"
        "nav content"
        "nav footer";
}*/

.layout {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: var(--header-height) auto auto;
    grid-template-areas:
        "aside-container header"
        "aside-container content"
        "aside-container footer";
}

.layout-login {
    display: grid;
    background-color: var(--main-color);
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "header"
        "content"
        "footer";

    & .container {
        max-width: 960px;
        flex-direction: row;

        @media screen and (min-width: 1400px) {
            max-width: 1180px;
        }
    }
}

.login-content {
    align-self: flex-start;
    padding-top: 70px;
    height: 100%;
}

.login-headline {
    font-size: 4.5rem;
    line-height: 5.5rem;
    color: var(--white-color);
    margin-bottom: 15px;
    margin-top: 0;

    @media screen and (min-width: 1200px) {
        font-size: 5.5rem;
        line-height: 6.5rem;
        margin-bottom: 25px;
        margin-top: 0;
    }
}

.login-content__article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 140px);
}

.login-logo {
    max-width: 325px;

    @media screen and (min-width: 1200px) {
        margin-left: -80px;
    }
}

.login__description-text {
    color: var(--white-color);
    width: 100%;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0;

    @media screen and (min-width: 1200px) {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
}

.aside-container {
    grid-area: aside-container;
}

/*.nav-trigger {
    grid-area: nav-trigger;
}

.nav {
    grid-area: nav;
}*/

.header {
    grid-area: header;
}

.main {
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 500px;

    & > .container {
        @media only screen and (max-width: 1100px) {
            padding: 0 20px;
        }
    }
}

.bg-grey {
    background-color: #f2f2f2;
}

.footer {
    grid-area: footer;
}

.bar-left {
    grid-area: bar-left;
}

.bar-right {
    grid-area: bar-right;
}

.container {
    width: 100%;
    max-width: 782px;
    margin: 0 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.is-2-col {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        & .richtext {
            width: 345px;
            flex-shrink: 0;
        }

        & h4 {
            margin: 0;
        }

        & p {
            margin-bottom: 0;
        }

        & .link-button {
            margin-top: auto;
        }
    }
}
