:root {
    --main-color: #9e1b18;
    --second-color: #6e0200;
    --secondary-text-color: #494949;
    --light-text-color: #1a1a1a;
    --input-background-color: #f5f5f5;
    --input-bottom-border-color: #000;
    --border-color: #d8d8d8;
    --white-color: #fff;
    --black-color: #000;
    --transparent-black-color: #00000020;
    --transparent-fourty-black-color: #00000040;
    --success-color: #40853f;
    --error-color: #a60700;
    --grey-bg-color: #f1f1f1;
    --blue-focus-color: #0000ff;
    --height-header: 183px;
    --height-breadcrumb: 71px;
    --base-spacing: 30px;
    --base-transition-duration: 200ms;
    --base-transition-delay: 400ms;
    --base-transition-ease: ease;
}
